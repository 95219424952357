<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row">
        <li class="nav-item">
            <a class="navbar-brand" [routerLink]="['/']">
                <span class="brand-logo">
                    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
                </span>
                <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
            </a>
        </li>
    </ul>
    <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [data-feather]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

        <!-- Toggle skin -->
        <li class="nav-item d-none d-lg-inline-flex">
            <a class="nav-link nav-link-style btn" placement="bottom" ngbTooltip="{{ currentSkin === 'dark' ? 'Világos mód' : 'Sötét mód' }}" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
            </a>
        </li>
        <!--/ Toggle skin -->

        <!-- Naptár -->
        <li class="nav-item d-none d-lg-inline-flex" *ngIf="!konyvelo">
            <a class="nav-link nav-link-style btn" placement="bottom" ngbTooltip="Naptár" [routerLink]="['/', 'torzsek', 'naptar']">
                <span class="ficon font-medium-5 feather" [data-feather]="'calendar'"></span>
            </a>
        </li>
        <!--/ Naptár -->

        <!-- Fuvaros naptár -->
        <li class="nav-item d-none d-lg-inline-flex" *ngIf="!konyvelo">
            <a class="nav-link nav-link-style btn" placement="bottom" ngbTooltip="Fuvaros naptár" [routerLink]="['/', 'torzsek', 'fuvaros-naptar']">
                <span class="ficon font-medium-5 feather" [data-feather]="'truck'"></span>
            </a>
        </li>
        <!--/ Fuvaros naptár -->

        <!-- Feladatok -->
        <li class="nav-item d-none d-lg-inline-flex" *ngIf="!konyvelo">
            <a class="nav-link nav-link-style btn" placement="bottom" ngbTooltip="Feladatok" [routerLink]="['/', 'torzsek', 'feladatok']" style="position:relative">
                <span class="ficon font-medium-5 feather" [data-feather]="'check-square'"></span>
                <span *ngIf="olvasatlan > 0" class="badge badge-pill badge-danger badge-up" style="top: -5px !important;
                right: -5px !important;
                min-width: 1.129rem !important;
                min-height: 1.129rem !important;">{{ olvasatlan }}</span>
            </a>
        </li>
        <!--/ Feladatok -->

        <!-- Súgó -->
        <li class="nav-item d-none d-lg-inline-flex" *ngIf="!konyvelo">
            <a class="nav-link nav-link-style btn" placement="bottom" ngbTooltip="Súgó" style="position:relative" (click)="openModal(sugo)">
                <span class="ficon font-medium-5 feather" [data-feather]="'help-circle'"></span>
            </a>
        </li>
        <!--/ Súgó -->

        <!-- Beállítások -->
        <li class="nav-item d-none d-lg-inline-flex" *ngIf="!konyvelo">
            <a class="nav-link nav-link-style btn" placement="bottom" ngbTooltip="Beállítások" (click)="openSettings()">
                <span class="ficon font-medium-5 feather" [data-feather]="'settings'"></span>
            </a>
        </li>
        <!--/ Beállítások -->

        <div ngbDropdown [placement]="'bottom'" *ngIf="this.bizonylatok?.data.olvasas">
            <button ngbDropdownToggle type="button" class="btn nav-link nav-link-style" rippleEffect ngbTooltip="Bizonylatok">
                <span class="ficon font-medium-5 feather" [data-feather]="'grid'"></span>
            </button>
            <div ngbDropdownMenu>
                <a ngbdropdownitem="" class="dropdown-item ng-star-inserted" [routerLink]="['/', 'torzsek', 'ajanlat']" *ngIf="this.ajanlat?.data.olvasas"><span class="ficon font-medium-5 feather"
                        [data-feather]="'file-text'"></span> Árajánlat </a>
                <a ngbdropdownitem="" class="dropdown-item ng-star-inserted" [routerLink]="['/', 'torzsek', 'szallitolevel']" *ngIf="this.szallito?.data.olvasas"><span class="ficon font-medium-5 feather"
                        [data-feather]="'file-text'"></span> Szállítólevél </a>
                <a ngbdropdownitem="" class="dropdown-item ng-star-inserted" [routerLink]="['/', 'torzsek', 'kimeno-szamla']" *ngIf="this.kimeno?.data.olvasas"><span class="ficon font-medium-5 feather"
                        [data-feather]="'file-text'"></span> Kimenő számla </a>
                <a ngbdropdownitem="" class="dropdown-item ng-star-inserted" [routerLink]="['/', 'torzsek', 'nyugta']" *ngIf="this.nyugta?.data.olvasas"><span class="ficon font-medium-5 feather"
                        [data-feather]="'file-text'"></span> Nyugta </a>
            </div>
        </div>
    </div>

    <ul class="nav navbar-nav align-items-center ml-auto">
        <!-- User Dropdown -->
        <li ngbDropdown class="nav-item dropdown-user">
            <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
                <div class="user-nav d-sm-flex d-none">
                    <span class="user-name font-weight-bolder">{{
                        currentUser?.nev
                        }}</span><span class="user-status">{{ currentUser?.oeNev }}</span>
                </div>
                <span class="avatar">
                    <img #avatar class="round"
                        src="{{currentUser?.avatar}}"
                        alt="avatar"
                        height="40" 
                        width="40" />
                    <span class="avatar-status-online"></span>
                </span>
            </a>
            <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
                <a *ngIf="!konyvelo" class="w-100" ngbDropdownItem [routerLink]="['/', 'torzsek', 'feladatok']">
                    <span [data-feather]="'check-square'" [class]="'mr-50'"></span> Feladatok
                </a>
                <a *ngIf="!konyvelo" class="w-100" ngbDropdownItem [routerLink]="['/', 'torzsek', 'naptar']">
                    <span [data-feather]="'calendar'" [class]="'mr-50'"></span> Naptár
                </a>
                <div *ngIf="!konyvelo" class="dropdown-divider"></div>
                <button *ngIf="!konyvelo" class="w-100" ngbDropdownItem (click)="openSettings()">
                    <span [data-feather]="'settings'" [class]="'mr-50'"></span>
                    Beállítások
                </button>
                <div *ngIf="!konyvelo" class="dropdown-divider"></div>
                <button class="w-100" ngbDropdownItem (click)="logout()">
                    <span [data-feather]="'power'" [class]="'mr-50'"></span> Kijelentkezés
                </button>
            </div>
        </li>
        <!--/ User Dropdown -->
    </ul>
</div>

<!-- Raklapkezelés modal -->
<ng-template #sugo let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Súgó</h4>
        <!-- <button type="button" class="btn" aria-label="Close" (click)="modal.dismiss('Cross click')">x</button> -->
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <ngb-carousel [showNavigationIndicators]="false" [interval]="0" (slide)="onSlide($event)" [activeId]="activeIndex">
        <ng-container *ngFor="let e of [].constructor(51); let i = index">
            <ng-template ngbSlide [id]="(i+1)">
                <div class="carousel-img-wrapper">
                    <img [src]="'https://aranyi.frederik.hu/print/tutorial/' + (i+1) + '.jpg'" class="img-fluid" alt="Random first slide" />
                </div>
                <div class="carousel-caption"></div>
            </ng-template>
        </ng-container>
    </ngb-carousel>
</ng-template>