import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, FormGroup, FormGroupDirective } from "@angular/forms";
import { Router } from "@angular/router";
import { Cikktorzs, Column, PenzugyiBizonylatTetel } from "../../models/torzsek";
import { CikktorzsService } from "../../services/cikktorzs.service";
import { findByProp } from "../../utils";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-torzsek-basic-table",
  templateUrl: "./torzsek-basic-table.component.html",
  styleUrls: ["./torzsek-basic-table.component.scss"],
  providers: [FormGroupDirective],
  host: { padding: "0.5rem" },
})
export class TorzsekBasicTableComponent implements OnInit, OnChanges {
  @Input() data: any[];
  @Input() cikkek: any[];
  @Input() tetelekControl: AbstractControl;
  @Input() torzs: any;
  @Input() raktarId: number;
  @Input() editMode: boolean;
  @Input() columns: Column[];
  @Input() tableClass?: string = "";
  @Input() tableStyle?: { [key: string]: any } = {};
  @Input() bodyMaxHeight?: string;
  @Input() selectedItem?: { dataItem: any; rowIndex: number };
  @Input() searchLayout: "basic" | "complex" = "basic";
  @Input() toolbarClass: string;
  @Input() disableActions?: boolean;
  @Input() height: string = "auto";
  @Output() selected = new EventEmitter<{ dataItem: any; rowIndex: number }>();
  @Output() remove = new EventEmitter<number>();
  @Output() valid = new EventEmitter<number>();
  @Output() invalid = new EventEmitter<number>();
  @Output() isDisabled = new EventEmitter<boolean>();

  _data: any[];
  _cikkek: any[];

  hiany: number;

  modalRef: NgbModalRef;
  formGroup: FormGroup;

  showSearchbar: boolean = false;

  beszerar_update: boolean = false;

  filteredColumns: Column[] = [];

  preventSelection: boolean;

  selectedCikk: any;
  cikkMegjegyzes: string;
  tetelIndex: any;

  get tetelek(): PenzugyiBizonylatTetel[] {
    return this.tetelekControl?.value || [];
  }

  route: string;

  constructor(
    private cikktorzsService: CikktorzsService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.columns) {
      this.filteredColumns = this.columns.filter((c: Column) => !c?.hidden);
    }
    this.cikktorzsService
      .getElerhetoKeszlet(
        JSON.parse(localStorage.getItem("currentUser")).raktar
      )
      .subscribe((resp) => {
        this._cikkek = resp;
      });
    this.route = this.router.url.split('/')[2];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("data" in changes) {
      let valid = true;
      this.data.forEach(row => {
        if(!this.checkRaktaron(row)) {
          valid = false;
          console.log(row);
        }
      });

      if(valid) {
        this.valid.emit();
      }

      if(this.data?.length < 1) {
        this.isDisabled.emit(false);
      } else {
        this.isDisabled.emit(true);
      }
      this._data = this.data || [];
    }
  }

  onRowSelect(dataItem: any, rowIndex: number): void {
    if (!this.preventSelection) {
      if(this.torzs == 'szallitolevel') {
        if(!dataItem.szallitolevel_id || this.editMode) {
          this.selected.emit({ dataItem, rowIndex });
        }     
      } else {
        this.selected.emit({ dataItem, rowIndex });
      }
    }
  }

  onRemove(index: number): void {
    this.remove.emit(index);
    this.preventSelection = false;
  }

  /**
   * Search (filter)
   *
   * @param event
   */
  filterUpdate(event) {
    const filter = event.target.value.toLowerCase();

    // filter our data
    this._data = this.filterByValue(this.data, filter);
  }

  private filterByValue(array: any[], filter: string) {
    return array.filter((o) =>
      Object.keys(o).some((k) => {
        if (o[k]) {
          return o[k].toString().toLowerCase().includes(filter.toLowerCase());
        } else {
          return false;
        }
      })
    );
  }

  checkRaktaron(tetel: any): boolean {
    if (this.torzs != "ajanlat" && this.route != 'bejovo-szamla' && this.route != 'bevetelezes' && this.route != 'elolegszamla' && this.route != 'dijbekero' && this.route != 'egyeb-raktari-bevet') {
      if (
        this.getSelectedCikk(tetel.cikk_id)?.aktualis_mennyiseg && 
        tetel.mennyiseg > this.getSelectedCikk(tetel.cikk_id).aktualis_mennyiseg &&
        !tetel.szallitolevel_id && !tetel.kulsoraktarieladas_id && tetel.cikkcsoport_id != 57 && tetel.cikkszam != 'vev001'
      ) {
        //this.parent.form.controls["mennyiseg"].setErrors({ incorrect: true });
        this.hiany = tetel.mennyiseg - this.getSelectedCikk(tetel.cikk_id).aktualis_mennyiseg;
        this.invalid.emit();
        return false;
      } else {
        //this.parent.form.controls["mennyiseg"].setErrors(null);
        // this.valid.emit();
        return true;
      }
    } else {
      return true;
    }
  }

  private getSelectedCikk(cikkId: number): Cikktorzs {
    return findByProp(this._cikkek, "id", cikkId);
  }

  changeBeszerarFrissit(dataItem: any) {
    this._data.forEach(item => {
      if(item.id == dataItem.id) {
        item.beszerar_frissit = !item.beszerar_frissit
      }
    })
  }

  openModal(content: any, tetel: any, tetelIndex: any): void {
    this.cikkMegjegyzes = tetel.megjegyzes || "";
    this.selectedCikk = tetel;
    this.tetelIndex = tetelIndex;
    // this.checkRaklapMennyiseg();
    this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  setMegjegyzes(tetel: any, ): void {

    const indexToUpdate = this.tetelIndex;//this.tetelek.findIndex(item => item.cikk_id === this.selectedCikk.id);
    this.tetelek[indexToUpdate] = {...this.tetelek[indexToUpdate], megjegyzes: this.cikkMegjegyzes };
    
    this.modalRef.close();
  }
}
