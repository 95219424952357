import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomToastService } from '@core/services/custom-toast.service';
import {
  PenzugyiBizonylatTetel,
  MennyisegiEgyseg,
} from 'app/main/pages/torzsek/models/torzsek';
import { KeszletkezelesTorzs } from 'app/main/pages/torzsek/types/torzsek.types';
import { calcBrutto, findByProp } from 'app/main/pages/torzsek/utils';

@Injectable()
export class TetelekToolbarService {
  constructor(private fb: FormBuilder, private toastr: CustomToastService) {}

  createListElement(
    torzs: KeszletkezelesTorzs,
    formValue: any,
    mennyisegiEgysegek: any[],
    helyesbites: boolean,
    selectedPenznem?: any 
  ) {
    const oszto = this.calcOszto(formValue);

    switch (torzs) {
      case 'bevetelezes':
      case 'egyeb-raktari-bevet': {
        return {
          ...formValue,
          mennyiseg: formValue.mennyiseg / oszto,
          mennyisegi_egyseg: formValue.me_id,
          mennyisegi_egyseg_text: this.getMeRovidnevById(
            formValue.me_id,
            mennyisegiEgysegek
          ),
          beszerzesi_ar: formValue.mennyiseg * formValue.beszerzesi_ar,
        };
      }

      case 'egyeb-raktari-kivet': {
        return {
          ...formValue,
          mennyiseg: formValue.mennyiseg / oszto,
          mennyisegi_egyseg: formValue.me_id,
          mennyisegi_egyseg_text: this.getMeRovidnevById(
            formValue.me_id,
            mennyisegiEgysegek
          ),
          eladasi_ar: +formValue.eladasi_ar * (formValue.mennyiseg / oszto),
        };
      }

      case 'bejovo-szamla': {
        const mennyiseg = formValue.mennyiseg / oszto;
        const nettoEgysegar = +formValue.netto_egysegar * oszto;
        const netto = selectedPenznem?.id == 1 ? Math.round((+nettoEgysegar * mennyiseg)) : Math.round(((+nettoEgysegar * mennyiseg)) * 100) / 100;
        //const afa_ar = netto * (formValue.afakulcs / 100);
        const afa_ar = selectedPenznem?.id == 1 ? Math.round(netto * (formValue.afakulcs / 100)) : Math.round((netto * (formValue.afakulcs / 100) * 100)) / 100;
        const brutto = netto + afa_ar;
        const bruttoEgysegar = Math.round((nettoEgysegar * (formValue.afakulcs / 100) + nettoEgysegar) * 100) / 100;
        const kapott = formValue.kapott_id;

        if(formValue.id === 1) {
          return {
            ...formValue,
            mennyiseg,
            mennyisegi_egyseg: formValue.me_id,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.me_id,
              mennyisegiEgysegek
            ),
            netto_egysegar: -nettoEgysegar,
            afa_egysegar: -(bruttoEgysegar - nettoEgysegar),
            brutto_egysegar: -bruttoEgysegar,
            netto_ar: -netto,
            afa_ar: -afa_ar,
            brutto_ar: -brutto,
            tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix
          };
        } else {
          return {
            ...formValue,
            mennyiseg,
            mennyisegi_egyseg: formValue.me_id,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.me_id,
              mennyisegiEgysegek
            ),
            netto_egysegar: nettoEgysegar,
            afa_egysegar: bruttoEgysegar - nettoEgysegar,
            brutto_egysegar: bruttoEgysegar,
            netto_ar: netto,
            afa_ar: afa_ar,
            brutto_ar: brutto,
            tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix
          };
        }
      }

      case 'bejovo-koltseg': {
        const mennyiseg = formValue.mennyiseg;
        const nettoEgysegar = +formValue.netto_egysegar;
        const netto = selectedPenznem?.id == 1 ? Math.round((+nettoEgysegar * mennyiseg)) : Math.round(((+nettoEgysegar * mennyiseg)) * 100) / 100;
        //const afa_ar = netto * (formValue.afakulcs / 100);
        const afa_ar = selectedPenznem?.id == 1 ? Math.round(netto * (formValue.afakulcs / 100)) : Math.round((netto * (formValue.afakulcs / 100) * 100)) / 100;
        const brutto = netto + afa_ar;
        const bruttoEgysegar = Math.round((nettoEgysegar * (formValue.afakulcs / 100) + nettoEgysegar) * 100) / 100;
        //const kapott = formValue.kapott_id;

        if(formValue.id === 1) {
          return {
            ...formValue,
            //cikk_nev: formValue.cikknev,
            mennyiseg,
            mennyisegi_egyseg: formValue.mennyisegi_egyseg,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.mennyisegi_egyseg,
              mennyisegiEgysegek
            ),
            netto_egysegar: -nettoEgysegar,
            afa_egysegar: -(bruttoEgysegar - nettoEgysegar),
            brutto_egysegar: -bruttoEgysegar,
            netto_ar: -netto,
            afa_ar: -afa_ar,
            brutto_ar: -brutto,
            //tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix
          };
        } else {
          return {
            ...formValue,
            //cikk_nev: formValue.cikknev,
            mennyiseg,
            mennyisegi_egyseg: formValue.mennyisegi_egyseg,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.mennyisegi_egyseg,
              mennyisegiEgysegek
            ),
            netto_egysegar: nettoEgysegar,
            afa_egysegar: bruttoEgysegar - nettoEgysegar,
            brutto_egysegar: bruttoEgysegar,
            netto_ar: netto,
            afa_ar: afa_ar,
            brutto_ar: brutto,
            //tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix
          };
        }
      }

      case 'elolegszamla': {
        const kedvezmeny = 1 - (formValue.kedvezmeny / 100);
        const mennyiseg = (formValue.mennyiseg / oszto);
        const nettoEgysegar = +formValue.netto_egysegar * oszto;
        const netto = selectedPenznem?.id == 1 ? Math.round((+nettoEgysegar * mennyiseg) * kedvezmeny) : Math.round(((+nettoEgysegar * mennyiseg) * kedvezmeny) * 100) / 100;
        //const afa_ar = netto * (formValue.afakulcs / 100);
        const afa_ar = selectedPenznem?.id == 1 ? Math.round(netto * (formValue.afakulcs / 100)) : Math.round((netto * (formValue.afakulcs / 100) * 100)) / 100;
        const brutto = netto + afa_ar;
        const bruttoEgysegar = Math.round((nettoEgysegar * (formValue.afakulcs / 100) + nettoEgysegar) * 100) / 100;
        const kapott = formValue.kapott_id;

        if(formValue.id === 1) {
          return {
            ...formValue,
            mennyiseg,
            mennyisegi_egyseg: formValue.me_id,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.me_id,
              mennyisegiEgysegek
            ),
            kedvezmeny: formValue.kedvezmeny,
            netto_egysegar: nettoEgysegar,
            afa_egysegar: (bruttoEgysegar - nettoEgysegar),
            brutto_egysegar: bruttoEgysegar,
            netto_ar: netto,
            afa_ar: afa_ar,
            brutto_ar: brutto,
            tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix,
            kiszamlazva: brutto
          };
        } else {
          return {
            ...formValue,
            mennyiseg,
            mennyisegi_egyseg: formValue.me_id,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.me_id,
              mennyisegiEgysegek
            ),
            kedvezmeny: formValue.kedvezmeny,
            netto_egysegar: nettoEgysegar,
            afa_egysegar: bruttoEgysegar - nettoEgysegar,
            brutto_egysegar: bruttoEgysegar,
            netto_ar: netto,
            afa_ar: afa_ar,
            brutto_ar: brutto,
            tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix,
          };
        }
      }

      // Ajánlat, bejövő, díjbekérő, előleg, foglalás, kimenő, nyugta, szállító,
      default: {
        const kedvezmeny = 1 - (formValue.kedvezmeny / 100);
        const mennyiseg = (helyesbites && torzs == 'kimeno-szamla') ? (+(formValue.uj_mennyiseg.replace(',','.')) / oszto) : (formValue.mennyiseg / oszto);
        const nettoEgysegar = +formValue.netto_egysegar * oszto;
        const netto = selectedPenznem?.id == 1 ? Math.round((+nettoEgysegar * mennyiseg) * kedvezmeny) : Math.round(((+nettoEgysegar * mennyiseg) * kedvezmeny) * 100) / 100;
        //const afa_ar = netto * (formValue.afakulcs / 100);
        const afa_ar = selectedPenznem?.id == 1 ? Math.round(netto * (formValue.afakulcs / 100)) : Math.round((netto * (formValue.afakulcs / 100) * 100)) / 100;
        const brutto = netto + afa_ar;
        const bruttoEgysegar = Math.round((nettoEgysegar * (formValue.afakulcs / 100) + nettoEgysegar) * 100) / 100;
        const kapott = formValue.kapott_id;

        if(formValue.id === 1) {
          return {
            ...formValue,
            mennyiseg,
            mennyisegi_egyseg: formValue.me_id,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.me_id,
              mennyisegiEgysegek
            ),
            kedvezmeny: formValue.kedvezmeny,
            netto_egysegar: -nettoEgysegar,
            afa_egysegar: -(bruttoEgysegar - nettoEgysegar),
            brutto_egysegar: -bruttoEgysegar,
            netto_ar: -netto,
            afa_ar: -afa_ar,
            brutto_ar: -brutto,
            tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix
          };
        } else {
          return {
            ...formValue,
            mennyiseg,
            mennyisegi_egyseg: formValue.me_id,
            mennyisegi_egyseg_text: this.getMeRovidnevById(
              formValue.me_id,
              mennyisegiEgysegek
            ),
            kedvezmeny: formValue.kedvezmeny,
            netto_egysegar: nettoEgysegar,
            afa_egysegar: bruttoEgysegar - nettoEgysegar,
            brutto_egysegar: bruttoEgysegar,
            netto_ar: netto,
            afa_ar: afa_ar,
            brutto_ar: brutto,
            tid: helyesbites ? kapott : formValue.tid,
            suffixProp: selectedPenznem?.outfix
          };
        }
      }
    }
  }

  createBejovoSzamlaForm() {
    let group: FormGroup;

    group = this.fb.group({
      beszerzesi_ar: [null],
      utolso_ar: [null],
      fix_ar: [null, Validators.required],
      netto_fix: [null, Validators.required],
      aktualis_mennyiseg: [null],
      me: [null]
    });

    return group;
  }

  createTetelForm(torzs: KeszletkezelesTorzs,
    options: { data?: any[]; helyesbites?: boolean } = {
      data: null,
      helyesbites: false,
    }): FormGroup {
    let group: FormGroup;

    switch (torzs) {
      case 'bevetelezes':
      case 'egyeb-raktari-bevet':
      case 'egyeb-raktari-kivet': {
        group = this.fb.group({
          id: [null],
          bevetezeles_id: [null],
          raktarkorrekcio_id: [null],
          // Cikk adatok
          cikk_id: [null, Validators.required],
          cikknev: [null],
          cikkcsoport_id: [null],
          beszerzesi_ar: [null],
          beszerzesi_ar_osszeg: [null],
          eladasi_ar: [null],
          me_id: [null],
          me_id_2: [null],
          me_id_3: [null],
          me1_oszto: [null],
          me2_oszto: [null],
          me3_oszto: [null],
          afakulcs: [null],
          afakulcs_id: [null],
          // Globális törzs adatok
          mennyiseg: [null, [Validators.required, Validators.min(0)]],
          mennyisegi_egyseg: [null, Validators.required],
          mennyisegi_egyseg_text: [null],
          // Egyéb
          raktar_id: [null],
          megjegyzes: [null],
        });

        break;
      }

      case 'bejovo-szamla': {
        group = this.fb.group({
          // DB tetel_id
          id: [null],
          // Hivatkozások
          kulsoraktarieladas_id: [null],
          ajanlat_id: [null],
          foglalas_id: [null],
          bevetelezes_id: [null],
          dijbekero_id: [null],
          elolegszamla_id: [null],
          kimenoszamla_id: [null],
          szallitolevel_id: [null],
          raktarkorrekcio_id: [null],
          // Cikk adatok
          cikk_id: [null, Validators.required],
          cikknev: [null],
          cikkcsoport_id: [null],
          beszerzesi_ar: [null],
          eladasi_ar: [null],
          me_id: [null],
          me_id_2: [null],
          me_id_3: [null],
          me1_oszto: [null],
          me2_oszto: [null],
          me3_oszto: [null],
          afakulcs: [null],
          afakulcs_id: [null, Validators.required],
          // Globális törzs adatok
          aktualis_mennyiseg: [null],
          // mennyiseg: [null, [Validators.required, Validators.min(0)]],
          // uj_mennyiseg: [null, [Validators.required, Validators.min(0)]],
          mennyiseg: [
            null,
            options?.helyesbites
              ? Validators.required // Helyesbítéskor lehet - is
              : [Validators.required], // Normális esetben csak pozitív vagy 0 lehet
          ],
          uj_mennyiseg: [
            null,
            options?.helyesbites
              ? [] // Helyesbítéskor lehet - is
              : [Validators.min(0)], // Normális esetben csak pozitív vagy 0 lehet
          ],
          mennyisegi_egyseg: [null, Validators.required],
          mennyisegi_egyseg_text: [null],
          // Törzs specifikus adatok
          netto_egysegar: [null, Validators.required],
          afa_egysegar: [null],
          brutto_egysegar: [null, Validators.required],
          netto_ar: [{ value: null, disabled: true }],
          brutto_ar: [{ value: null, disabled: true }],
          afa_ar: [{ value: null, disabled: true }],
          // Egyéb
          raktar_id: [null],
          megjegyzes: [null],
          tid: [null],
          kapott_id: [null]
        });
        break;
      }

      case 'szallitolevel': {
        group = this.fb.group({
          // DB tetel_id
          id: [null],
          // Hivatkozások
          kulsoraktarieladas_id: [null],
          ajanlat_id: [null],
          foglalas_id: [null],
          bevetelezes_id: [null],
          dijbekero_id: [null],
          elolegszamla_id: [null],
          kimenoszamla_id: [null],
          szallitolevel_id: [null],
          raktarkorrekcio_id: [null],
          arkategoria_id: [null],
          arkategoria: [null],
          // Cikk adatok
          cikk_id: [null, Validators.required],
          cikknev: [null],
          cikkcsoport_id: [null],
          beszerzesi_ar: [null],
          eladasi_ar: [null],
          me_id: [null],
          me_id_2: [null],
          me_id_3: [null],
          me1_oszto: [null],
          me2_oszto: [null],
          me3_oszto: [null],
          afakulcs: [null],
          afakulcs_id: [null, Validators.required],
          // Globális törzs adatok
          aktualis_mennyiseg: [null],
          // mennyiseg: [null, [Validators.required, Validators.min(0)]],
          // uj_mennyiseg: [null, [Validators.required, Validators.min(0)]],
          mennyiseg: [
            null,
            options?.helyesbites
              ? Validators.required // Helyesbítéskor lehet - is
              : [Validators.required], // Normális esetben csak pozitív vagy 0 lehet
          ],
          uj_mennyiseg: [
            null,
            options?.helyesbites
              ? [] // Helyesbítéskor lehet - is
              : [], // Normális esetben csak pozitív vagy 0 lehet
          ],
          mennyisegi_egyseg: [null, Validators.required],
          mennyisegi_egyseg_text: [null],
          // Törzs specifikus adatok
          netto_egysegar: [null, Validators.required],
          afa_egysegar: [null, Validators.required],
          brutto_egysegar: [null, Validators.required],
          kedvezmeny: [null, [Validators.min(0), Validators.max(50)]],
          netto_ar: [{ value: null, disabled: true }],
          brutto_ar: [{ value: null, disabled: true }],
          afa_ar: [{ value: null, disabled: true }],
          // Egyéb
          raktar_id: [null],
          megjegyzes: [null],
          tid: [null],
          kapott_id: [null]
        });
        break;
      }

      case 'bejovo-koltseg': {
        group = this.fb.group({
          // DB tetel_id
          id: [null],
          // Hivatkozások
          kulsoraktarieladas_id: [null],
          ajanlat_id: [null],
          foglalas_id: [null],
          bevetelezes_id: [null],
          dijbekero_id: [null],
          elolegszamla_id: [null],
          kimenoszamla_id: [null],
          szallitolevel_id: [null],
          raktarkorrekcio_id: [null],
          // Cikk adatok
          cikk_id: [null],
          cikknev: [null, Validators.required],
          cikkcsoport_id: [null],
          beszerzesi_ar: [null],
          eladasi_ar: [null],
          me_id: [null],
          me_id_2: [null],
          me_id_3: [null],
          me1_oszto: [null],
          me2_oszto: [null],
          me3_oszto: [null],
          afakulcs: [null],
          afakulcs_id: [null, Validators.required],
          // Globális törzs adatok
          aktualis_mennyiseg: [null],
          // mennyiseg: [null, [Validators.required, Validators.min(0)]],
          // uj_mennyiseg: [null, [Validators.required, Validators.min(0)]],
          mennyiseg: [
            null,
            options?.helyesbites
              ? Validators.required // Helyesbítéskor lehet - is
              : [Validators.required], // Normális esetben csak pozitív vagy 0 lehet
          ],
          uj_mennyiseg: [
            null,
            options?.helyesbites
              ? [] // Helyesbítéskor lehet - is
              : [Validators.min(0)], // Normális esetben csak pozitív vagy 0 lehet
          ],
          mennyisegi_egyseg: [null, Validators.required],
          mennyisegi_egyseg_text: [null],
          // Törzs specifikus adatok
          netto_egysegar: [null, Validators.required],
          //afa_egysegar: [null, Validators.required],
          //brutto_egysegar: [null, Validators.required],
          netto_ar: [{ value: null, disabled: true }],
          brutto_ar: [{ value: null, disabled: true }],
          afa_ar: [{ value: null, disabled: true }],
          // Egyéb
          raktar_id: [null],
          megjegyzes: [null],
          tid: [null],
          kapott_id: [null]
        });
        break;
      }

      case 'foglalas': {
        group = this.fb.group({
          // DB tetel_id
          id: [null],
          // Hivatkozások
          kulsoraktarieladas_id: [null],
          ajanlat_id: [null],
          foglalas_id: [null],
          bevetelezes_id: [null],
          dijbekero_id: [null],
          elolegszamla_id: [null],
          kimenoszamla_id: [null],
          szallitolevel_id: [null],
          raktarkorrekcio_id: [null],
          arkategoria_id: [null],
          arkategoria: [null],
          // Cikk adatok
          cikk_id: [null, Validators.required],
          cikknev: [null],
          cikkcsoport_id: [null],
          beszerzesi_ar: [null],
          eladasi_ar: [null],
          me_id: [null],
          me_id_2: [null],
          me_id_3: [null],
          me1_oszto: [null],
          me2_oszto: [null],
          me3_oszto: [null],
          afakulcs: [null],
          afakulcs_id: [null, Validators.required],
          // Globális törzs adatok
          aktualis_mennyiseg: [null],
          // mennyiseg: [null, [Validators.required, Validators.min(0)]],
          // uj_mennyiseg: [null, [Validators.required, Validators.min(0)]],
          mennyiseg: [
            null,
            options?.helyesbites
              ? [Validators.min(0)] // Helyesbítéskor lehet - is
              : [Validators.required, Validators.min(0)], // Normális esetben csak pozitív vagy 0 lehet
          ],
          uj_mennyiseg: [
            null,
            options?.helyesbites
              ? [Validators.required, Validators.min(0)] // Helyesbítéskor lehet - is
              : [Validators.min(0)], // Normális esetben csak pozitív vagy 0 lehet
          ],
          mennyisegi_egyseg: [null, Validators.required],
          mennyisegi_egyseg_text: [null],
          // Törzs specifikus adatok
          netto_egysegar: [null, Validators.required],
          afa_egysegar: [null, Validators.required],
          brutto_egysegar: [null, Validators.required],
          kedvezmeny: [null, [Validators.min(0), Validators.max(50)]],
          netto_ar: [{ value: null, disabled: true }],
          brutto_ar: [{ value: null, disabled: true }],
          afa_ar: [{ value: null, disabled: true }],
          // Egyéb
          raktar_id: [null],
          megjegyzes: [null],
          tid: [null],
          kapott_id: [null]
        });
        break;
      }

      // Ajánlat, bejövő, díjbekérő, előleg, foglalás, kimenő, nyugta, szállító,
      default: {
        group = this.fb.group({
          // DB tetel_id
          id: [null],
          // Hivatkozások
          kulsoraktarieladas_id: [null],
          ajanlat_id: [null],
          foglalas_id: [null],
          bevetelezes_id: [null],
          dijbekero_id: [null],
          elolegszamla_id: [null],
          kimenoszamla_id: [null],
          szallitolevel_id: [null],
          raktarkorrekcio_id: [null],
          arkategoria_id: [null],
          arkategoria: [null],
          // Cikk adatok
          cikk_id: [null, Validators.required],
          cikknev: [null],
          cikkcsoport_id: [null],
          beszerzesi_ar: [null],
          eladasi_ar: [null],
          me_id: [null],
          me_id_2: [null],
          me_id_3: [null],
          me1_oszto: [null],
          me2_oszto: [null],
          me3_oszto: [null],
          afakulcs: [null],
          afakulcs_id: [null, Validators.required],
          // Globális törzs adatok
          aktualis_mennyiseg: [null],
          // mennyiseg: [null, [Validators.required, Validators.min(0)]],
          // uj_mennyiseg: [null, [Validators.required, Validators.min(0)]],
          mennyiseg: [
            null,
            options?.helyesbites
              ? [] // Helyesbítéskor lehet - is
              : [Validators.required, Validators.min(0)], // Normális esetben csak pozitív vagy 0 lehet
          ],
          uj_mennyiseg: [
            null,
            options?.helyesbites
              ? Validators.required // Helyesbítéskor lehet - is
              : [Validators.min(0)], // Normális esetben csak pozitív vagy 0 lehet
          ],
          mennyisegi_egyseg: [null, Validators.required],
          mennyisegi_egyseg_text: [null],
          // Törzs specifikus adatok
          netto_egysegar: [null, Validators.required],
          afa_egysegar: [null, Validators.required],
          brutto_egysegar: [null, Validators.required],
          kedvezmeny: [null, [Validators.min(0), Validators.max(50)]],
          netto_ar: [{ value: null, disabled: true }],
          brutto_ar: [{ value: null, disabled: true }],
          afa_ar: [{ value: null, disabled: true }],
          // Egyéb
          raktar_id: [null],
          megjegyzes: [null],
          tid: [null],
          kapott_id: [null]
        });
        break;
      }
    }

    if (options?.data) {
      group.patchValue(options?.data);
    }

    return group;
  }

  getIndexInList(t: PenzugyiBizonylatTetel, array: any[]): number {
    return array.findIndex((e: any) => {
      // console.log('List: ', e, 'New:', t);
      return (
        e.cikk_id === t.cikk_id &&
        e?.netto_egysegar === t?.netto_egysegar &&
        e?.afa_egysegar === t?.afa_egysegar &&
        e?.brutto_egysegar === t?.brutto_egysegar &&
        e?.eladasi_ar === t?.eladasi_ar &&
        e?.beszerzesi_ar === t?.beszerzesi_ar &&
        e?.kedvezmeny === t?.kedvezmeny
      );
    });
  }

  updateListItem(
    rowIndex: number,
    formValue: PenzugyiBizonylatTetel,
    mennyisegiEgysegek: MennyisegiEgyseg[],
    tetelek: PenzugyiBizonylatTetel[],
    torzs: string,
    calcEladasiAr?: boolean,
  ): PenzugyiBizonylatTetel[] {
    const c = tetelek[rowIndex];

    // TODO: Mennyiség figyelése
    let mennyiseg = c.mennyiseg + formValue.mennyiseg;

    if (c.aktualis_mennyiseg && mennyiseg > c.aktualis_mennyiseg) {
      // Info toast
      if (torzs != 'bevetelezes' && torzs != 'ajanlat' && torzs != 'bejovo-szamla' && torzs != 'egyeb-raktari-bevet' && torzs != 'dijbekero' && torzs != 'foglalas' && torzs != 'bejovo-koltseg') {
        this.showTulcsordultMennyisegToast('max');

        mennyiseg = c.aktualis_mennyiseg;
      }
    }

    // const mappedElement = {
    //   ...c,
    //   mennyiseg,
    //   mennyisegi_egyseg_text: this.getMeRovidnevById(
    //     c.me_id,
    //     mennyisegiEgysegek
    //   ),
    //   eladasi_ar: this.calcEladasiArByTorzs(c, formValue, calcEladasiAr),
    // };

    const mappedElement = {
      ...c,
      mennyiseg: c.mennyiseg + formValue.mennyiseg,
      mennyisegi_egyseg_text: this.getMeRovidnevById(
        c.me_id,
        mennyisegiEgysegek
      ),
      netto_ar: c.netto_ar + formValue.netto_ar,
      brutto_ar: c.brutto_ar + formValue.brutto_ar,
      afa_ar: c.afa_ar + formValue.afa_ar,
      eladasi_ar: this.calcEladasiArByTorzs(c, formValue, calcEladasiAr),
    };

    const newState = tetelek.map((e: any, i: number) =>
      i === rowIndex ? mappedElement : e
    );

    return newState;
  }

  getMeRovidnevById(meId: number, data: any[]): string {
    return findByProp(data, 'id', meId)?.rovidnev;
  }

  filterByValue(array: any[], filter: string) {
    return array.filter((o) =>
      Object.keys(o).some((k) => {
        if (o[k]) {
          return o[k].toString().toLowerCase().includes(filter.toLowerCase());
        } else {
          return false;
        }
      })
    );
  }

  showTulcsordultMennyisegToast(type: string): void {
    if(type == 'min') {
      this.toastr.toastrCustomInfo(
        'Túlcsordult mennyiség',
        'Mennyiség minimalizálva'
      );
    } else if (type == 'max'){
      this.toastr.toastrCustomInfo(
        'Túlcsordult mennyiség',
        'Mennyiség maximalizálva'
      );
    } else if (type == 'keszlet') {
      this.toastr.toastrCustomInfo(
        'Raktár hiba',
        'Készlet eltérés'
      );
    } else if (type == 'kedvezmeny') {
      this.toastr.toastrCustomInfo(
        'Rossz kedvezmény',
        'A kedvezményes ár alacsonyabb a beszerzési árnál'
      );
    } else if (type == 'beszerar') {
      this.toastr.toastrCustomInfo(
        'Beszerzési allatti értékesítés',
        'A megadott ár alacsonyabb a beszerzési árnál'
      );
    } else if(type == 'szallitolevel') {
      this.toastr.toastrCustomInfo(
        'Bizonylat össz. mennyiség hiba',
        'A bizonylaton szereplő tételek össz. mennyisége nem lehet negatív',
        'szallito'
      );
    } else if(type == 'nulla') {
      this.toastr.toastrCustomInfo(
        'Tetel mennyiség hiba',
        'A tétel mennyiségnek 0-tól eltérőnek kell lennie',
        'szallito'
      );
    } else if(type == 'nincs_listaban') {
      this.toastr.toastrCustomInfo(
        'Szállítólevél lista  hiba',
        'A tétel nem található a bizonylat tételei között.',
        'szallito'
      );
    }
  }

  calcOszto(formValue: PenzugyiBizonylatTetel): number {
    const me = formValue.mennyisegi_egyseg;
    let oszto = 1; // Mennyiség / (1 / kiválasztott me osztója)

    // Marad a mennyiség
    if (formValue.me_id === me) {
      oszto = 1;
    }

    // Osztunk me2 osztóval
    else if (formValue.me_id_2 === me) {
      if (!formValue.me2_oszto) {
        console.log('Konvertálás hibás, mert nincs megadott me2 osztó!');
      }

      oszto = 1 / formValue.me2_oszto || 1;
    }

    // Osztunk me3 osztóval
    else if (formValue.me_id_3 === me) {
      if (!formValue.me3_oszto) {
        console.log('Konvertálás hibás, mert nincs megadott me2 osztó!');
      }

      oszto = 1 / formValue.me3_oszto || 1;
    }
    return oszto;
  }

  private calcEladasiArByTorzs(
    c: PenzugyiBizonylatTetel,
    formValue: PenzugyiBizonylatTetel,
    calcEladasiAr?: boolean
  ) {
    return calcEladasiAr
      ? c.eladasi_ar * (c.mennyiseg + formValue.mennyiseg)
      : c.eladasi_ar;
  }

  calcValuta(arfolyamok: any[], osszeg_ft: number, penznem_id: number): number {
    const valuta = arfolyamok.find(item => item.id == penznem_id);

    return osszeg_ft / valuta.arfolyam;
  }
}
